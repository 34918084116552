<template>
    <div>
        <div v-if="!shipmentPicker.show">
            <b-container fluid>
                <b-row>
                    <b-col class="pl-0">
                        <b-button variant="link" @click="save()">Сохранить</b-button>
                        <b-button variant="link" @click="$router.go(-1)">Закрыть</b-button>
                    </b-col>
                    <b-col class="text-right">
                        <b-button variant="primary" @click="commit()">Подтвердить в DLV</b-button>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12" md="3">
                        <b-form-group label="Номер">
                            <b-form-input :disabled="entity.id!==null" v-model="entity.num"/>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="3">
                        <b-form-group label="Дата">
                            <b-form-datepicker :disabled="entity.id!==null" v-model="entity.date"/>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="3">
                        <b-form-group label="DLV Login">
                            <b-form-input :disabled="entity.id!==null" v-model="entity.dlvLogin"/>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="3">
                        <b-form-group label="ContractId">
                            <b-form-input :disabled="entity.id!==null" v-model="entity.contractId"/>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="3">
                        <b-form-group label="DlvId">
                            <b-form-input disabled v-model="entity.dlvId"/>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="3">
                        <b-form-group label="Тип реестра">
                            <b-form-select :disabled="entity.id!==null" v-model="entity.type" :options="typeOptions"/>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col xl="6" md="6" sm="6" cols="12">
                        <b-form-group label="Добавить отправление">
                            <entity-chooser :init="shipmentPicker.fieldValue"
                                            :items="shipmentPicker.items"
                                            @picker-click="shipmentPicker.query = ''; shipmentPicker.show = true"
                                            @input="shipmentPicker.query = $event"
                                            @select="pickShipments"
                                            :placeholder="'Выберите отправления'"/>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-table :items="shipments" :fields="tableFields">
                    <template v-slot:cell(actions)="data">
                        <b-link class="ml-4" @click="removeShipment(data.item, index)">
                            Убрать
                        </b-link>
                    </template>
                </b-table>

            </b-container>
        </div>
        <div>
            <shipment-picker v-show="shipmentPicker.show" @pick="pickShipments"
                             @suggestions="$event.forEach(item => $set(item, 'value', item.label)); shipmentPicker.items = $event;"
                             :query="shipmentPicker.query"
                             select-mode="multi"
                             :init-ids="entity.shipments">

            </shipment-picker>
        </div>
    </div>
</template>

<script>

    import EntityChooser from "../EntityChooser";
    import ShipmentPicker from "./ShipmentPicker";

    export default {
        name: "ShipmentEditor",
        components: {ShipmentPicker, EntityChooser},
        created() {
            if (this.$route.params.id) {
                this.getEntity(this.$route.params.id);
                this.getShipments(this.$route.params.id);
            }

        },
        data() {
            return {
                entity: {
                    id: null,
                    num: null,
                    date: null,
                    contractId: null,
                    dlvId: null,
                    shipments: []
                },
                shipments: [],
                tableFields: [
                    {
                        key: 'num',
                        label: 'Номер'
                    }, {
                        key: 'shipmentType',
                        label: 'Тип'
                    }, {
                        key: 'dlvLogin',
                        label: 'Аккаунт'
                    }, {
                        key: 'status',
                        label: 'Статус',
                    }, {
                        key: 'courierCode',
                        label: 'Служба доставки',
                    }, {
                        key: 'courierNum',
                        label: 'Номер отслеживания КС',
                    }, {
                        key: 'dlvId',
                        label: 'DLV Id',
                    }, {
                        key: 'actions',
                        label: 'Действия'
                    }
                ],
                shipmentPicker: {
                    show: false,
                    fieldValue: null,
                    query: null,
                    items: []
                },
                typeOptions: [
                    {
                        value: null,
                        text: 'Тип реестра',
                    }, {
                        value: 'GATHER',
                        text: 'Сбор'
                    }, {
                        value: 'DELIVERY',
                        text: 'Доставка'
                    }, {
                        value: 'RETURN',
                        text: 'Возврат'
                    },
                ],

            }
        },
        methods: {
            save() {
                this.$http.post(this.$root.apiUrl + '/api/register', this.entity, {headers: this.$root.getHeaders()})
                    .then(response => {
                        if (response.body.success) {
                            this.$router.push("/registers");
                        } else {
                            alert(response.body.errors.map(error => error.message).reduce((acc, value) => acc + '<br>' + value));
                        }
                    }, response => {
                        this.$root.handleErrorResponse(response);
                    });
            },
            getEntity(id) {
                this.$http.get(this.$root.apiUrl + '/api/register/' + id, {headers: this.$root.getHeaders()})
                    .then(response => {
                            if (response.body.success) {
                                this.entity = response.body.entity;
                            } else {
                                alert(response.body.errors.map(error => error.message).reduce((acc, value) => acc + '<br>' + value));
                            }
                        },
                        response => {
                            this.$root.handleErrorResponse(response);
                        }
                    );
            },
            getShipments(id) {
                this.$http.get(this.$root.apiUrl + '/api/shipments/' + id,
                    {
                        params: {
                            page: 0,
                            size: 100,
                            filter: JSON.stringify([
                                {
                                    key: "shipmentType",
                                    value: null,
                                    operation: "="
                                },
                            ])
                        },
                        headers: this.$root.getHeaders()
                    }
                ).then(response => {
                    if (response.body.success) {
                        this.shipments = response.body.list;
                        this.entity.shipments = response.body.list.map(value => value.id);
                    } else {
                        alert(response.body.errors.map(error => error.message).reduce((acc, value) => acc + '<br>' + value));
                    }
                }, response => {
                    this.$root.handleErrorResponse(response);
                });
            },
            pickShipments(array) {
                if (array) {
                    array.forEach(value => {
                        this.shipments.push(value)
                        this.entity.shipments.push(value.id);
                    });

                }
                this.shipmentPicker.show = false;
            },
            removeShipment(shipment, index) {
                this.shipments.splice(index, 1);
                this.entity.shipments.splice(index, 1);
            },
            commit() {
                this.$http.post(this.$root.apiUrl + '/api/commit-register', {id: this.entity.id}, {headers: this.$root.getHeaders()})
                    .then(response => {
                        if (response.body.success) {
                            alert("Успешно");
                        } else {
                            alert(response.body.errors.map(error => error.message).reduce((acc, value) => acc + '<br>' + value));
                        }
                    }, response => {
                        this.$root.handleErrorResponse(response);
                    });
            },
        }
    }
</script>

<style scoped>
</style>